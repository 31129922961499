import {createTheme, ThemeOptions, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {lazy, ReactNode, Suspense} from "react";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {useTranslation} from "react-i18next";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider,} from "react-router-dom";
import {LazyMotion} from "./components/LazyMotion";
import i18n from "./i18n";
import {customPageLoader} from "./pages/CustomPage";
import {LoadingPage} from "./pages/LoadingPage";
import {RightToLeft} from "./RightToLeft";
import {useSettings} from "./utilities/hooks";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/ar";

dayjs.extend(duration);

document.body.dir = i18n.dir();

// const ZoneListPage = lazy(() => import('./pages/ZoneListPage'));
// const HomePage = lazy(() => import('./pages/HomePage'));
// const IframePage = lazy(() => import('./pages/IframePage'));
// const MapPage = lazy(() => import('./pages/MapPage'));
// const OpportunityListPage = lazy(() => import('./pages/OpportunityListPage'));
// const OpportunityPage = lazy(() => import('./pages/OpportunityPage'));
// const SuccessStoryListPage = lazy(() => import('./pages/SuccessStoryListPage'));
// const TestimonialListPage = lazy(() => import('./pages/TestimonialListPage'));
const ChangePasswordPage = lazy(() => import("./pages/ChangePasswordPage"));
const CustomPage = lazy(() => import("./pages/CustomPage"));
const ForgetPasswordPage = lazy(() => import("./pages/ForgetPasswordPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const RegisterPage = lazy(() => import("./pages/RegisterPage"));
const CustomRegisterPage = lazy(() => import("./pages/CustomRegisterPage"));

const WordpressPage = lazy(() => import("./pages/WordpressPage"));
const RedirectPage = lazy(() => import("./pages/RedirectPage"));

const Layout = lazy(() => import("./components/Layout"));
const FormLayout = lazy(() => import("./components/FormLayout"));
const CustomFormLayout = lazy(() => import("./components/CustomFormLayout"));

const baseTheme: ThemeOptions = {
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: 1400,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#bcbec0",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-input": {
            backgroundColor: "white",
            borderRadius: 12,
          },
          fieldset: {
            border: "none",
          },
          label: {
            color: "#bcbec0",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-input": {
            backgroundColor: "white",
            borderRadius: 12,
          },
          fieldset: {
            border: "none",
          },
          label: {
            color: "#bcbec0",
          },
        },
      },
    },
    // MuiSvgIcon: {
    //   styleOverrides: {
    //     root: {
    //       'body[dir=rtl] &': {
    //         transform: 'scaleX(-1)',
    //       }
    //     }
    //   },
    // },
  },
  palette: {
    background: {
      paper: "#f1f2f2",
    },
    info: {
      main: "#FFFFFF",
    },
    primary: {
      contrastText: "#FFFFFF",
      main: "#2B4B9B",
    },
    secondary: {
      contrastText: "#FFFFFF",
      main: "#71B62C",
    },
    success: {
      contrastText: "#bcbec0",
      main: "#bcbec0",
    },
    text: {
      primary: "#2B4B9B",
      secondary: "#71B62C",
    },
  },
};

const arTheme: ThemeOptions = {
  direction: "rtl",
  typography: {
    fontFamily: "Zain",
  },
};

const enTheme: ThemeOptions = {
  direction: "ltr",
  typography: {
    fontFamily: "Zain",
  },
};

const createSuspense = (component: ReactNode) => {
  return <Suspense fallback={<LoadingPage/>}>{component}</Suspense>;
};

const routes = createRoutesFromElements(
  <Route>
    <Route element={createSuspense(<CustomFormLayout/>)}>
      <Route element={createSuspense(<CustomRegisterPage/>)} path="/custom-register"/>
    </Route>
    <Route element={createSuspense(<FormLayout/>)}>
      <Route
        element={createSuspense(<ChangePasswordPage/>)}
        path="/change-password/:email/:code"
      />
      <Route
        element={createSuspense(<ForgetPasswordPage/>)}
        path="/forget-password"
      />
      <Route element={createSuspense(<LoginPage/>)} path="/login"/>
      <Route element={createSuspense(<RegisterPage/>)} path="/register"/>
    </Route>

    <Route
      element={createSuspense(<WordpressPage closed={false}/>)}
      path="/الفعالة"
    />

    <Route
      element={createSuspense(<WordpressPage closed={true}/>)}
      path="/المنتهية"
    />

    <Route
      element={createSuspense(<WordpressPage/>)}
      path="/المسابقات"
    >
      <Route
        element={createSuspense(<WordpressPage/>)}
        path=":slug"
      />
    </Route>

    <Route
      element={createSuspense(<RedirectPage/>)}
      path="/redirect"
    />

    <Route element={createSuspense(<Layout/>)}>
      <Route
        element={createSuspense(<CustomPage/>)}
        loader={customPageLoader}
        path="/:slug"
      >
        <Route
          element={createSuspense(<CustomPage/>)}
          loader={customPageLoader}
          path=":id"
        />
      </Route>
    </Route>

    <Route element={createSuspense(<HomePage/>)} path="*"/>
  </Route>
);

const router = createBrowserRouter(routes);

export const App = () => {
  // const { mode } = useMode();

  const {i18n} = useTranslation();

  const settings = useSettings();

  if (settings == null) {
    return <LoadingPage/>;
  }

  console.log("settings", settings);

  const theme = createTheme({
    ...baseTheme,
    ...(i18n.language === "ar" ? arTheme : enTheme),
  });

  return (
    <RightToLeft>
      <ThemeProvider theme={theme}>
        <LazyMotion>
          <GoogleReCaptchaProvider reCaptchaKey={settings.global.recaptcha_key}>
            <LocalizationProvider
              adapterLocale={i18n.language}
              dateAdapter={AdapterDayjs}
            >
              <RouterProvider router={router}/>
            </LocalizationProvider>
          </GoogleReCaptchaProvider>
        </LazyMotion>
      </ThemeProvider>
    </RightToLeft>
  );
};
